/* Add this CSS to a separate stylesheet (e.g., styles.css) and import it into your component */

.color-palette {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .page-allow-overflow {
    overflow: visible; /* or auto, or any other desired overflow value */
  }
  
  .color-swatch {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .color-box {
    width: 50px;
    height: 50px;
    border-radius: 4px;
  }
  
  .color-text {
    margin-top: 5px;
    font-family: monospace;
  }
  