body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  /* overflow-y: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spin-container {
  position: absolute;
  width: 100vh;
  height: 100vh;
  justify-items: center;
  align-items: center;
  display: flex;
}

.no-spin-container {
  position: absolute;
  width: 100vh;
  height: 100vh;
  justify-items: center;
  align-items: center;
  display: flex;
}

.spin-element {
  position: absolute;
  width: 80vh;
  animation: spin 6s linear infinite; /* Adjust the animation duration as needed */
  transform-origin: center;
  
}

.no-spin-element {
  position: absolute;
  width: 80vh;
  /* animation: spin 6s linear infinite; Adjust the animation duration as needed */
  transform-origin: center;
  margin-left: 10%;
}

.title-scaling {
  position: 'absolute';
  top: 0;
  left: 0;
  margin-top: min(10vh, 10vw);
  margin-left: min(10vh, 10vw)
  
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
